
.logo {
    display: flex;
    align-items: center;
  }
  
  .logo img {
    margin-right: 10px;
  }

.material {
    background-color: #F8EA76;
}

.material a {
    color: #2374B8;
    text-decoration: underline;
}

.material_header{
    background-color: #ffffff;
}

.paddingStyle{
    font-size: large;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 20px;
    display: block;
    text-align: center;
}

.paddingList{
    font-size: large;
    padding-left: 400px;
    padding-right: 400px;
}

.material-title {
    position: relative;
    width: 328px;
    height: 35px;
    display: flex;
    align-items: center;
    font-size: 24px;
    left: 50%;
    transform: translateX(-50%);
    justify-content: center;
}

.material-title::before,
.material-title::after {
    content: '';
    flex-grow: 1;
    height: 3px;
    background-color: #333333;
}

.material-title:before {
    margin-right: 1em;
}

.material-title:after {
    margin-left: 1em;
}

@media screen and (max-width:480px) {
    .material-title {
        position: relative;
        width: 328px;
        height: 35px;
        display: flex;
        align-items: center;
        font-size: 24px;
        left: 50%;
        transform: translateX(-50%);
        justify-content: center;
    }

.paddingStyle{
    font-size: large;
    padding-left: 20px;
    padding-right: 20px;
}


.paddingList{
    font-size: large;
    padding-left: 100px;
    padding-right: 100px;
}
}