.App {
  text-align: center;
  width: 100%;
}

#root {
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  width: 100%;
  margin: auto;
}

.body {
  width: 100%;
  min-width: 1440px;
  box-sizing: border-box;
}

button {
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  font: inherit;
  color: inherit;
  outline: none;
  appearance: none;
}

.header {
  width: 100%;
  height: 80px;
  background-color: #ffffff;
  display: flex;
  position: relative;
}

a {
  text-decoration: none;
  color: #333333;
}

.br-sp {
  display: none;
}

.sect-title {
  font-size: 46px;
}

/* .logo {
  vertical-align: middle;
  width: 10%;
} */

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  margin-right: 10px;
}

.pc-menu {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding-left: 20px;
}

.sp-menu {
  display: none;
}

.drawer {
  display: none;
}

.garoop-icon {
  vertical-align: middle;
}

.header-ul {
  vertical-align: middle;
  width: 90%;
}

.header-ul ul {
  list-style: none;
  gap: 1em;
  font-size: 14px;
  font-weight: 700;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}

.mail1 {
  display: table-cell;
  vertical-align: middle;
}

.mail1 span {
  display: inline-flex;
}

.mail1 a {
  display: grid;
  border-radius: 70px;
  background: #b5e03c;
  align-items: center;
  list-style: none;
  font-size: 14px;
  font-weight: 700;
}

.main {
  width: 100%;
  background-color: #f8ea76;
}

/* FV */

.firstview {
  max-height: 600px;
  max-width: 1440px;
  margin-inline: auto;
  padding-inline: 20px;
  padding-block-end: 250px;

  background-image: url(../src/img/first view/Frame.png),
    url(../src/img/first view/Frame2.png), url(../src/img/first view/Frame3.png),
    url(../src/img/first view/Frame4.png), url(../src/img/first view/Frame5.png),
    url(../src/img/first view/Frame6.png), url(../src/img/first view/Frame8.png),
    url(../src/img/first view/Frame9.png),
    url(../src/img/first view/Frame10.png),
    url(../src/img/first view/Frame7.png);

  background-size: calc(237.88 / 1400 * 100%) auto,
    calc(237.99 / 1400 * 100%) auto, calc(160.55 / 1400 * 100%) auto,
    calc(333.38 / 1400 * 100%) auto, calc(198.33 / 1400 * 100%) auto,
    calc(141.66 / 1400 * 100%) auto, calc(169.99 / 1400 * 100%) auto,
    calc(306.93 / 1400 * 80%) auto, calc(188.88 / 1400 * 100%) auto,
    calc(166.22 / 1400 * 100%) auto;

  background-repeat: no-repeat;

  background-position: 20px bottom, 10px 10px, 27.5% calc(100% - 110px),
    25% bottom, 42% calc(100% - 30px), 56% bottom, 78% calc(100% - 65px),
    calc(100% - 50px) calc(100% - 40px), calc(100% - 40px) 10px,
    66% calc(100% - 80px);
}

.garuchanIcon{
  margin-right: 10px;
}

.garoop-service-component {
  align-items: center;
  justify-content: center;
  display: flex;
  margin: auto;
  text-align: center;
  text-decoration: none;
  width: 300px;
  padding: 1rem 1rem;
  font-weight: bold !important;
  background: #f197da !important;
  color: #f8f8f8 !important;
  border-bottom: 2px solid #eeecea !important;
  border-radius: 9999px !important;
  box-shadow: 0 2px 7px rgba(0, 0, 0, .3) !important;
  -webkit-box-shadow: 0 2px 7px rgba(0, 0, 0, .3) !important;
  transition: 0.5s !important;
}

.garoop-service-component:hover {
  color: #fff !important;
  background: #b6ae90 !important;
  border-bottom: 2px solid #fff !important;
  transform: translateY(3px) !important;
}

.hptitle {
  max-height: 350px;
  align-items: center;
  width: calc(1000 / 1400 * 100%);
  max-width: 750px;
  aspect-ratio: 991 / 550;
  height: auto;
  mix-blend-mode: darken;
}

.we {
  max-width: 1440px;
  margin-inline: auto;
  padding-inline: 20px;
  margin-block-start: 40px;
  padding: 30px 20px 100px;
  background-color: #f8ea76;

  background-image: url(../src/img/we/build003.png),
    url(../src/img/we/Layer_7.png), url(../src/img/we/Layer_3.png),
    url(../src/img/we/Layer_4.png), url(../src/img/we/Frame.png),
    url(../src/img/we/Layer_1_copy.png), url(../src/img/we/Layer_2.png);
  background-repeat: no-repeat;
  background-size: calc(386 / 1400 * 100%) auto, calc(90 / 1400 * 100%) auto,
    calc(123 / 1400 * 100%) auto, calc(237 / 1400 * 100%) auto,
    calc(420 / 1440 * 100%) auto, calc(150 / 1440 * 100%) auto,
    calc(110 / 1440 * 100%) auto;
  background-position: 75% 100px, 82% 440px, 66% 590px, calc(100% - 80px) 480px,
    center calc(50% + 160px), 70px calc(50% + 350px), 23% calc(50% + 230px);
}

.we-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 46px;
}

.we-title::before,
.we-title::after {
  content: "";
  flex-grow: 1;
  height: 3px;
  background-color: #333333;
}

.we-title:before {
  margin-right: 1em;
}

.we-title:after {
  margin-left: 1em;
}

.we-img {
  margin-block-start: 60px;
}

.cloud1txt {
  margin-inline-end: auto;
}

.cloud1ttl {
  position: absolute;
  color: #333333;
  top: 1170px;
  left: 30%;
  transform: translateX(-50%);
  white-space: nowrap;
  font-size: 24px;
  font-weight: bold;
}

.cloud1explain {
  color: #333333;
  display: block;
  width: calc(680 / 1440 * 100%);
  min-width: 320px;
  white-space: nowrap;
  background: url(../src/img/we/e0308_0.png) no-repeat center center / contain;
  aspect-ratio: 512 / 395;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-block-end: 55px;
}
.cloud2explain {
  color: #333333;
  display: block;
  width: calc(507 / 1440 * 100%);
  min-width: 320px;
  white-space: nowrap;
  background: url(../src/img/we/e0308_0.png) no-repeat center center / contain;
  aspect-ratio: 420 / 326;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-block-end: 55px;
  line-height: 1.7;
}
.cloud3explain {
  color: #333333;
  display: block;
  width: calc(507 / 1440 * 100%);
  min-width: 315px;
  white-space: nowrap;
  background: url(../src/img/we/e0308_0.png) no-repeat center center / contain;
  aspect-ratio: 420 / 326;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-block-end: 55px;
  line-height: 1.7;
  transform: scale(-1, 1);
  margin-inline-start: auto;
  margin-block-start: -145px;
}
.cloud4explain {
  color: #333333;
  display: block;
  width: calc(562 / 1440 * 100%);
  min-width: 315px;
  white-space: nowrap;
  background: url(../src/img/we/e0308_0.png) no-repeat center center / contain;
  aspect-ratio: 562 / 446;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-block-end: 55px;
  line-height: 1.7;
  transform: scale(1, -1);
}
.cloud5explain {
  color: #333333;
  display: block;
  width: calc(437 / 1440 * 100%);
  min-width: 265px;
  white-space: nowrap;
  background: url(../src/img/we/e0308_0.png) no-repeat center center / contain;
  aspect-ratio: 437 / 326;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-block-end: 55px;
  line-height: 1.7;
  transform: scale(-1, -1);
  margin-inline-start: auto;
  margin-block-start: -430px;
}

.cloud1explain p,
.cloud2explain p,
.cloud3explain p,
.cloud4explain p,
.cloud5explain p {
  display: inline;
  font-size: 18px;
  line-height: 1.9;
  text-align: left;
}

.cloud3explain-inner {
  transform: scale(-1, 1);
}

.cloud4explain-inner {
  transform: scale(1, -1);
}

.cloud5explain-inner {
  transform: scale(-1, -1);
}

.main a {
  color: #2374b8;
  text-decoration: underline;
}

.cloud2ttl {
  position: absolute;
  color: #333333;
  top: 1650px;
  left: 25%;
  transform: translateX(-50%);
  white-space: nowrap;
  font-size: 24px;
  font-weight: bold;
}

.cloud2explain a {
  color: #2374b8;
  text-decoration: underline;
}

span {
  display: block;
}

.cloud3ttl {
  position: absolute;
  color: #333333;
  top: 1805px;
  left: 1120px;
  white-space: nowrap;
  font-size: 24px;
  font-weight: bold;
}

.cloud3explain a {
  color: #2374b8;
  text-decoration: underline;
}

.we li {
  list-style: none;
}

.cloud4ttl {
  position: absolute;
  color: #333333;
  top: 2400px;
  left: 310px;
  white-space: nowrap;
  font-size: 24px;
  font-weight: bold;
}

.cloud5ttl {
  position: absolute;
  color: #333333;
  top: 2400px;
  left: 1060px;
  white-space: nowrap;
  font-size: 24px;
  font-weight: bold;
}

.cloud4explain a {
  color: #2374b8;
  text-decoration: underline;
}

.cloud5explain a {
  color: #2374b8;
  text-decoration: underline;
}

/* Service */

.service {
  background-color: #f8ea76;
  max-width: 1040px;
  margin-inline: auto;
  padding-inline: 20px;
  margin-block-start: 60px;
}

.service-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 46px;
  margin-inline: auto;
}

.service-title::before,
.service-title::after {
  content: "";
  flex-grow: 1;
  height: 3px;
  background-color: #333333;
}

.service-title:before {
  margin-right: 1em;
}

.service-title:after {
  margin-left: 1em;
}

.service-inner {
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 40px;
  margin-block-start: 50px;
}

.pctxt,
.hptxt {
  width: calc(50% - 40px);
  border-radius: 40px;
  background-color: #fff;
  padding: 30px 20px;
  flex-grow: 1;
}

.pcttl {
  position: relative;
}

.pcttl h3 {
  position: relative;
  display: block;
  color: #333333;
  font-size: 24px;
  font-weight: bold;
  border: 1px solid #333;
  background-color: #ffffff;
  z-index: 1;
  padding-inline: 16px;
}

.pcshadow {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 6px;
  left: 6px;
  background-color: #ffb866;
  z-index: 0;
}

.pc {
  margin-block-start: 20px;
}

.pcimg {
  width: 60%;
  margin-inline: auto;
  height: 300px;
}

.pcimg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.pcexplain {
  margin-block-start: 20px;
  text-align: left;
  padding-inline: 50px;
  color: #333333;
  font-size: 18px;
  text-align: left;
  line-height: 170%;
}

.pcexplain li {
  list-style: none;
}

.hp {
  margin-block-start: 20px;
}

.ec1ttl {
  position: relative;
}

.ec1ttl h3 {
  position: relative;
  display: block;
  color: #333333;
  font-size: 24px;
  font-weight: bold;
  border: 1px solid #333;
  background-color: #ffffff;
  z-index: 1;
}

.ec1shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 6px;
  left: 6px;
  background-color: #ffb866;
  z-index: 0;
}
.hpimg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.hpexplain {
  color: #333333;
  font-size: 18px;
  text-align: left;
  line-height: 170%;
  padding-inline: 30px;
  margin-block-start: 20px;
}

.video {
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
}

/* 技術 */
.technology {
  margin-block-start: 40px;
  background-color: #f8ea76;
  position: relative;
  max-width: 1040px;
  margin-inline: auto;
  padding: 30px 20px 100px;
  background: url(../src/img/technology/b_simple_128_0L 1.png) no-repeat center
    bottom / contain;
}

.technology-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 46px;
  margin-inline: auto;
}

.technology-title::before,
.technology-title::after {
  content: "";
  flex-grow: 1;
  height: 3px;
  background-color: #333333;
}

.technology-title:before {
  margin-right: 1em;
}

.technology-title:after {
  margin-left: 1em;
}

.technology-grid {
  margin-block-start: 100px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 150px;
}

.circle1 {
  translate: -100px 0;
}
.circle2 {
  translate: 0 80px;
}
.circle3 {
  translate: 80px 0;
}
.circle4 {
  translate: 40px;
}
.circle5 {
  translate: 240px -100px;
}

.circle1txt {
  background-color: #ffffff;
  width: 221px;
  height: 221px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 50%;
  border: 3px solid #00a7ea;
}

.circle1ttl {
  font-size: 24px;
  display: inline-block;
  width: fit-content;
  background: linear-gradient(
    180deg,
    transparent 0%,
    transparent 50%,
    #ffffbf 50%,
    #ffffbf 100%
  );
}

.circle1explain {
  /* position: absolute; */
  /* top: 95px;
  left: 65px; */
  text-align: left;
  line-height: 170%;
}

.circle1explain li {
  list-style: none;
}

.circle2txt {
  background-color: #ffffff;
  width: 350px;
  height: 350px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 50%;
  border: 3px solid #8c4021;
}

.circle2txt::before {
  content: "";
  position: absolute;
  width: 95px;
  height: 95px;
  bottom: -75%;
  right: -90%;
  background: url(../src/img/technology/Vector2.png) no-repeat center center /
    contain;
}

.circle2txt::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -56%;
  left: -130px;
  background: url(../src/img/technology/Layer_1.png) no-repeat center center /
    45% auto;
}

.circle2ttl {
  font-size: 24px;
  display: inline-block;
  width: fit-content;
  background: linear-gradient(
    180deg,
    transparent 0%,
    transparent 50%,
    #ffffbf 50%,
    #ffffbf 100%
  );
}

.circle2explain {
  /* position: absolute; */
  top: 120px;
  left: 120px;
  text-align: left;
  line-height: 170%;
}

.circle3txt {
  background-color: #ffffff;
  width: 200px;
  height: 200px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 50%;
  border: 3px solid #ec9f1e;
}

.circle3txt::after {
  content: "";
  position: absolute;
  width: 188px;
  height: 168px;
  bottom: -23%;
  right: 5%;
  background: url(../src/img/technology/Layer_2.png) no-repeat center center /
    100% auto;
}
.circle3txt::before {
  position: absolute;
  content: "";
  display: block;
  background: #f6a3af99;
  width: 95px;
  height: 95px;
  bottom: -46%;
  right: 28%;
  border-radius: 50%;
  transform: translateX(50%);
}

.circle3ttl {
  font-size: 24px;
  display: inline-block;
  width: fit-content;
  background: linear-gradient(
    180deg,
    transparent 0%,
    transparent 50%,
    #ffffbf 50%,
    #ffffbf 100%
  );
}

.circle3explain {
  /* position: absolute; */
  top: 100px;
  left: 75px;
}

.circle3explain li {
  list-style: none;
}

.circle4txt {
  background-color: #ffffff;
  width: 244px;
  height: 244px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 50%;
  border: 3px solid #ff1a3c;
  position: relative;
}

.circle4txt::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -80%;
  left: 10px;
  background: url(../src/img/technology/Layer_3.png) no-repeat center center /
    contain;
}
.circle4txt::before {
  content: "";
  position: absolute;
  top: 8%;
  right: -80%;
  background: #ec9b1466;
  width: 95px;
  height: 95px;
  display: block;
  border-radius: 50%;
}

.circle4ttl {
  font-size: 24px;
  display: inline-block;
  width: fit-content;
  background: linear-gradient(
    180deg,
    transparent 0%,
    transparent 50%,
    #ffffbf 50%,
    #ffffbf 100%
  );
}

.circle4explain {
  /* position: absolute; */
  top: 75px;
  left: 88px;
  text-align: left;
  line-height: 160%;
}

.circle4explain li {
  list-style: none;
}

.circle5txt {
  background-color: #ffffff;
  width: 255px;
  height: 255px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 50%;
  border: 3px solid #00a65a;
}

.circle5txt::before {
  content: "";
  display: block;
  position: absolute;
  background: url(../src/img/technology/Vector.png) no-repeat center center /
    contain;
  top: -80%;
  right: 25%;
  width: 55px;
  height: 55px;
}
.circle5txt::after {
  content: "";
  display: block;
  position: absolute;
  background: #00a7ea82;
  width: 95px;
  height: 95px;
  border-radius: 50%;
  top: -190%;
  right: -60%;
}

.circle5ttl {
  font-size: 24px;
  display: inline-block;
  width: fit-content;
  background: linear-gradient(
    180deg,
    transparent 0%,
    transparent 50%,
    #ffffbf 50%,
    #ffffbf 100%
  );
}

.circle5explain {
  text-align: left;
  line-height: 170%;
}

.circle5explain li {
  list-style: none;
}

.owl {
  position: absolute;
  top: 177px;
  left: 40%;
  transform: translateX(-50%);
}

.bluestar {
  position: absolute;
  top: 379px;
  right: 30%;
  transform: translateX(50%);
}

.togemouse2 {
  position: absolute;
  top: 533px;
  left: 23%;
  transform: translateX(-50%);
}

.mouse2 {
  position: absolute;
  top: 468px;
  right: 19%;
  transform: translateX(50%);
}

.blackstar {
  position: absolute;
  top: 799px;
  right: 19%;
  transform: translateX(50%);
}

/* Recruit */

.recruit {
  background-color: #f8ea76;

  max-width: 1040px;
  margin-inline: auto;
  padding-inline: 20px;
  margin-block-start: 60px;
  padding-block-end: 60px;
}

.recruit-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 46px;
  margin-inline: auto;
}

.recruit-title::before,
.recruit-title::after {
  content: "";
  flex-grow: 1;
  height: 3px;
  background-color: #333333;
}

.recruit-title:before {
  margin-right: 1em;
}

.recruit-title:after {
  margin-left: 1em;
}

.recruitttl {
  border-top: 2px solid #333333;
  border-bottom: 2px solid #333333;
  text-align: center;
  font-size: 24px;
  width: fit-content;
  padding-inline: 20px;
  margin-inline: auto;
  margin-block-start: 40px;
}

.recruitttl2 {
  border-top: 2px solid #333333;
  border-bottom: 2px solid #333333;
  text-align: center;
  font-size: 18px;
  width: fit-content;
  padding-inline: 20px;
  margin-inline: auto;
  margin-block-start: 40px;
}

.ha2 {
  /* position: absolute; */
  top: 4800px;
  left: 27%;
}

.acorn {
  /* position: absolute; */
  top: 4800px;
  left: 40%;
  transform: translateX(-50%);
}

.pinkrose {
  /* position: absolute; */
  top: 4900px;
  right: 23%;
  transform: translateX(50%);
}

.redrose {
  /* position: absolute; */
  top: 5220px;
  left: 25%;
  transform: translateX(-50%);
}

.haacorn {
  /* position: absolute; */
  top: 5190px;
  right: 23%;
  transform: translateX(50%);
}

.dear2 {
  /* position: absolute; */
  top: 5020px;
  left: 50%;
  transform: translateX(-50%);
}

.fireflower2 {
  /* position: absolute; */
  top: 5066px;
  right: 33%;
  transform: translateX(50%);
}

.recruit-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 120px;
  row-gap: 200px;
  position: relative;
  margin-block-start: 60px;
}

.recruit-grid::after {
  content: "";
  position: absolute;
  width: calc(330 / 1040 * 100%);
  max-width: 330px;
  height: 100%;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url(../src/img/recruit/Layer_6.png) no-repeat center center /
    contain;
}

.one {
  position: relative;
}

.one::before {
  content: "";
  position: absolute;
  width: 40px;
  height: 80px;
  top: -20px;
  left: 20%;
  background: url(../src/img/recruit/Layer_1.png) no-repeat center center /
    contain;
}

.one::after {
  content: "";
  position: absolute;
  width: 60px;
  height: 60px;
  top: -20px;
  right: 20%;
  background: url(../src/img/recruit/Layer_2.png) no-repeat center center /
    contain;
}

.onettl {
  font-size: 42px;
  margin-bottom: 20px;
}

.onetxt {
  font-size: 24px;
  margin-bottom: 20px;
}

.oneexplain {
  font-size: 16px;
  text-align: left;
}

.oneexplain p {
  margin-bottom: 20px;
}

.two {
  position: relative;
}

.two::before {
  content: "";
  position: absolute;
  width: 105px;
  height: 100px;
  top: 80px;
  right: 10px;
  background: url(../src/img/recruit/Layer_3.png) no-repeat center center /
    contain;
}
.two::after {
  content: "";
  position: absolute;
  width: 138px;
  height: 100px;
  bottom: -30px;
  left: 45%;
  transform: translateX(-50%);
  background: url(../src/img/recruit/Layer_7.png) no-repeat center center /
    contain;
}
.twottl {
  font-size: 42px;
  margin-bottom: 20px;
}

.twotxt {
  font-size: 24px;
  margin-bottom: 20px;
}

.twoexplain {
  font-size: 16px;
  text-align: left;
}

.twoexplain p {
  margin-bottom: 20px;
}

.three {
  position: relative;
}

.three::before {
  content: "";
  position: absolute;
  width: 114px;
  height: 102px;
  top: -30px;
  left: 0;
  background: url(../src/img/recruit/Layer_4.png) no-repeat center center /
    contain;
}

.threettl {
  font-size: 42px;
  margin-bottom: 20px;
}

.threetxt {
  font-size: 24px;
  margin-bottom: 20px;
}

.threeexplain {
  font-size: 16px;
  text-align: left;
}

.threeexplain p {
  margin-bottom: 20px;
}

.four {
  position: relative;
}

.four::before {
  content: "";
  position: absolute;
  width: 147px;
  height: 109px;
  top: -25%;
  right: 20px;
  background: url(../src/img/recruit/Layer_5.png) no-repeat center center /
    contain;
}

.fourttl {
  font-size: 42px;
  margin-bottom: 20px;
}

.fourtxt {
  font-size: 24px;
  margin-bottom: 20px;
}

.fourexplain {
  font-size: 16px;
  text-align: left;
}

.fourexplain p {
  margin-bottom: 20px;
}

.conditions {
  margin-block-start: 80px;
  display: flex;
  gap: 60px;
}

.treatment {
  position: relative;
  border: dashed 3px #333333;
  border-radius: 5px;
  width: calc(391 / 1040 * 100%);
  padding: 30px 12px;
}

.treatment-title {
  position: absolute;
  top: 0;
  left: 50%;
  translate: -50% -50%;
  font-size: 24px;
  padding: 8px 56px 7px 56px;
  background-color: #f8ea76;
}

.treatmentexplain {
  text-align: left;
  font-size: 16px;
  list-style-position: inside;
}

.vacation {
  position: relative;
  border: dashed 3px #333333;
  border-radius: 5px;
  width: calc(588 / 1040 * 100%);
  padding: 30px 12px;
}

.vacation-title {
  position: absolute;
  top: 0;
  left: 50%;
  translate: -50% -50%;
  font-size: 24px;
  padding: 8px 56px 7px 56px;
  background-color: #f8ea76;
}

.vacationexplain {
  text-align: left;
  font-size: 16px;
  list-style-position: inside;
}
.vacation3 li {
  margin-top: 10px;
  list-style-image: url("../src/img/recruit/material-symbols_check-box-outline.png");
  list-style-position: inside;
}

.vacation3 ul li:nth-child(2) {
  list-style-image: url("../src/img/recruit/ic_round-check-box.png");
}

.vacation3 ul {
  display: flex;
  justify-content: center;
  justify-content: space-between;
}

.blackcheck {
  list-style-image: url("../src/img/recruit/ic_round-check-box.png");
}

.whitecheck {
  list-style-image: url("../src/img/recruit/material-symbols_check-box-outline.png");
}

.part {
  width: calc(740 / 1080 * 100%);
  padding: 40px 20px;
  background-color: #ffffff;
  border-radius: 40px;
  position: relative;
  display: grid;
  place-items: center;
  gap: 20px;
  margin-block-start: 60px;
}

.part::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 100px;
  clip-path: polygon(0 0, 100% 50%, 0 100%);
  right: -49px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
}

.part::before {
  content: "";
  position: absolute;
  display: block;
  width: calc(280 / 1080 * 100%);
  aspect-ratio: 1 / 1;
  right: -35%;
  top: 50%;
  transform: translateY(-50%);
  background: url(../src/img/recruit/Layer_8.png) no-repeat center center /
    contain;
}

.partttl {
  /* position: absolute; */
  top: 28px;
  left: 264px;
  height: 35px;
  font-size: 24px;
  text-align: center;
}

.partexplain {
  /* position: absolute; */
  height: 120px;
  top: 93px;
  left: 204px;
  text-align: left;
  font-size: 21px;
}

.point {
  display: block;
  color: #ffffff;
  font-size: 24px;
  padding: 55px 20px 45px;
  margin-block-start: 80px;

  background: url(../src/img/recruit/f1318_4 1.png) no-repeat center center /
    contain;
}

.merit {
  margin-block-start: 40px;
}

.meritttl {
  width: fit-content;
  padding-inline: 20px;
  color: #ffffff;
  background-color: #333333;
  font-weight: 400;
  font-size: 18px;
  line-height: 50px;
  margin-inline: auto;
}

.meritexplain {
  text-align: left;
  font-size: 16px;
  margin-block-start: 40px;
}

.meritexplain li {
  list-style-position: inside;
}

.meritexplain1 {
}

.attention {
  margin-block-start: 40px;
}

.attentionttl {
  width: fit-content;
  padding-inline: 20px;
  color: #ffffff;
  background-color: #333333;
  font-weight: 400;
  font-size: 18px;
  line-height: 50px;
  margin-inline: auto;
}

.attentionexplain {
  text-align: left;
  font-size: 16px;
  margin-block-start: 40px;
}

.attentionexplain ul {
  width: 100%;
}

.attentionexplain li {
  width: 100%;
  align-content: center;
  list-style-position: inside;
}

.attentionexplain1 {
  width: 100%;
}

.selectionttl {
  border-top: 2px solid #333333;
  border-bottom: 2px solid #333333;
  text-align: center;
  font-size: 24px;
  width: fit-content;
  padding-inline: 40px;
  margin-inline: auto;
  margin-block-start: 60px;
}

.selection-flow {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  margin-block-start: 100px;
}

.selection-step {
  width: calc(308 / 1040 * 100%);
  flex-grow: 1;
  position: relative;
  list-style: none;
}

.selection-stepInner {
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
}

.bluecircle {
  position: absolute;
  left: 50%;
  top: 0;
  translate: -50% -50%;
}

.girltxt {
  background-color: #ffffff;
  border-radius: 40px;
  border: 4px solid #2374b8;
  position: relative;
}

.girltxt::after,
.boytxt::after {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  top: 50%;
  right: -32px;
  transform: translateY(-50%);
  background: url(../src/img/recruit/gl_triangle-right-blue.png.png) no-repeat
    center center / contain;
}

.girl {
}

.girlttl {
  font-size: 22px;
  font-weight: 700;
}

.girlexplain {
  text-align: left;
  font-size: 16px;
}

.girlexplain div {
  display: inline-block;
  color: #2374b8;
  text-decoration: underline;
}

.bluecircle1 {
  font-weight: 350;
  font-size: 28px;
  line-height: 60px;
  color: #ffffff;
  background-color: #2374b8;
  width: 66px;
  height: 66px;
  border-radius: 50%;
  text-align: center;
}

.boytxt {
  background-color: #ffffff;
  border-radius: 40px;
  border: 4px solid #2374b8;
}

.boy {
}

.boyttl {
  font-size: 22px;
  font-weight: 700;
}

.boyexplain {
  text-align: left;
  font-size: 16px;
}

.bluecircle2 {
  font-weight: 350;
  font-size: 28px;
  line-height: 60px;
  color: #ffffff;
  background-color: #2374b8;
  width: 66px;
  height: 66px;
  border-radius: 50%;
  text-align: center;
}

.marrytxt {
  background-color: #ffffff;
  border-radius: 40px;
  border: 4px solid #2374b8;
}

.marry {
}

.marryttl {
  font-size: 22px;
  font-weight: 700;
}

.marryexplain {
  text-align: center;
  font-size: 16px;
}

.bluecircle3 {
  font-weight: 350;
  font-size: 28px;
  line-height: 60px;
  color: #ffffff;
  background-color: #2374b8;
  width: 66px;
  height: 66px;
  border-radius: 50%;
  text-align: center;
}

.triangle1 {
}

.triangle2 {
}

/* 会社概要 */

.company {
  background-color: #f8ea76;
  max-width: 1040px;
  margin-inline: auto;
  padding-inline: 20px;
  margin-block-start: 60px;
  padding-block-end: 60px;
}

.company-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 46px;
}

.company-title::before,
.company-title::after {
  content: "";
  flex-grow: 1;
  height: 3px;
  background-color: #333333;
}

.company-title:before {
  margin-right: 1em;
}

.company-title:after {
  margin-left: 1em;
}

.box {
  margin-block-start: 60px;
  border-collapse: collapse;
  margin-inline: auto;
  width: 100%;
  position: relative;
}

.box::after {
  content: "";
  display: block;
  width: 150px;
  height: 160px;
  background: url(../src/img/company/img.png) no-repeat center center / contain;
  position: absolute;
  top: 0;
  right: 0;
  translate: 25% -50%;
}

.box th {
  background-color: #e5ae83;
  color: #ffffff;
  width: 140px;
  height: 77px;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  border-bottom: 1px solid #ffffff;
}

.box td {
  background-color: #ffffff;
  color: #333333;
  width: 710px;
  height: 77px;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  padding-left: 20px;
  border-bottom: 1px solid #f8c7a3;
}

/* お問い合わせ */

.info {
  background-color: #f8ea76;
  max-width: 1040px;
  margin-inline: auto;
  padding-inline: 20px;
  margin-block-start: 60px;
  padding-block-end: 60px;
}

.info-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 46px;
}

.info-title::before,
.info-title::after {
  content: "";
  flex-grow: 1;
  height: 3px;
  background-color: #333333;
}

.info-title:before {
  margin-right: 1em;
}

.info-title:after {
  margin-left: 1em;
}
.infotxt {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  margin-block-start: 80px;
  text-align: center;
}
.info-list {
  display: flex;
  justify-content: space-between;
  margin-block-start: 150px;
  gap: 40px;
}

.info-item {
  list-style: none;
  width: calc(300 / 1040 * 100%);
  background-color: #ffffff99;
  border-radius: 46px;
  padding: 80px 20px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.info-item img {
  display: block;
  width: 100px;
  aspect-ratio: 1 / 1;
  margin-inline: auto;
}

.info-explain {
  margin-block-start: 50px;
  text-align: center;
  justify-content: center;
  white-space: nowrap;
}

.mail2 {
  position: relative;
}

.mail2::before {
  content: "";
  position: absolute;
  display: block;
  width: 75px;
  height: 100px;
  top: -100px;
  left: 30%;
  background: url(../src/img/info/Layer_1.png) no-repeat center center / contain;
}
.mail2::after {
  content: "";
  position: absolute;
  display: block;
  width: 70px;
  height: 75px;
  top: -75px;
  left: 55%;
  background: url(../src/img/info/Layer_2.png) no-repeat center center / contain;
}

.mail2explain {
  font-weight: 900;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #333333;
  font-family: Noto Sans JP;
}

.google {
}

.googleexplain {
  font-weight: 900;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #333333;
}

.twitter1 {
  position: relative;
}

.twitter1::before {
  content: "";
  position: absolute;
  display: block;
  width: 140px;
  height: 95px;
  top: -80px;
  left: 50%;
  transform: translateX(-50%);
  background: url(../src/img/info/Layer_3.png) no-repeat center center / contain;
}

.twitter1explain {
  font-weight: 900;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #333333;
  z-index: 100;
}

/* フッター */
.footer {
  background-color: #ffffff;
  position: relative;
  padding: 40px 20px 20px;
}
.footer .pc-menu {
  justify-content: center;
}

.footerlogo {
  position: absolute;
  width: 120px;
  height: 90px;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}

.footerimg {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-block-start: 20px;
}

.footerul {
  flex-wrap: nowrap;
}

.footerul li {
  list-style: none;
  display: inline-block;
  padding: 15px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  width: auto;
  flex-wrap: nowrap;
}

.footergaroop {
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  margin-block-start: 20px;
}

@media screen and (max-width: 1280px) {
  .firstview {
    padding-block-end: 150px;

    background-position: 20px bottom, 10px 10px, 27.5% calc(100% - 90px),
      25% bottom, 42% calc(100% - 30px), 56% bottom, 78% calc(100% - 65px),
      calc(100% - 50px) calc(100% - 40px), calc(100% - 60px) 10px,
      66% calc(100% - 80px);
  }

  .we {
    background-position: 75% 100px, 82% 410px, 66% 500px,
      calc(100% - 80px) 440px, center calc(50% + 160px), 70px calc(50% + 350px),
      23% calc(50% + 230px);
  }

  .cloud1explain p,
  .cloud2explain p,
  .cloud3explain p,
  .cloud4explain p,
  .cloud5explain p {
    font-size: 16px;
    line-height: 1.6;
  }

  .cloud5explain {
    margin-block-start: -360px;
    margin-inline-end: 30px;
  }

  .pcexplain,
  .hpexplain {
    padding-inline: 20px;
  }

  .circle1 {
    translate: -20px 0;
  }
  .circle2 {
    translate: 0 80px;
  }
  .circle3 {
    translate: 0 0;
  }
  .circle4 {
    translate: 40px;
  }
  .circle5 {
    translate: 220px -100px;
  }
  .circle2txt::before {
    right: -80%;
  }
  .circle5txt::after {
    right: -20%;
  }

  .box::after {
    translate: 0 -50%;
  }
}

@media screen and (max-width: 980px) {
  .firstview {
    background-position: 20px bottom, 10px 10px, 27.5% calc(100% - 60px),
      25% bottom, 42% calc(100% - 20px), 56% bottom, 78% calc(100% - 45px),
      calc(100% - 30px) calc(100% - 20px), calc(100% - 40px) 10px,
      66% calc(100% - 50px);
  }

  .we {
    background-position: 75% 80px, 82% 345px, 66% 380px, calc(100% - 80px) 370px,
      center calc(50% + 130px), 70px calc(50% + 300px), 23% calc(50% + 200px);
  }

  .cloud2explain {
    margin-block-start: -20px;
  }
  .cloud5explain {
    margin-block-start: -260px;
  }

  .service-inner {
    gap: 20px;
    margin-block-start: 30px;
    flex-direction: column;
  }

  .pctxt,
  .hptxt {
    box-sizing: border-box;
    width: 100%;
    padding: 26px 16px;
  }

  .circle1 {
    translate: 0 0;
  }
  .circle2 {
    translate: 0 80px;
  }
  .circle3 {
    translate: 0 0;
  }
  .circle4 {
    translate: 40px;
  }
  .circle5 {
    translate: 220px -100px;
  }

  .circle1txt {
    width: 182px;
    height: 182px;
  }
  .circle2txt {
    width: 285px;
    height: 285px;
  }
  .circle3txt {
    width: 165px;
    height: 165px;
  }
  .circle4txt {
    width: 220px;
    height: 220px;
  }
  .circle5txt {
    width: 215px;
    height: 215px;
  }

  .circle2txt::before {
    width: 80px;
    height: 80px;
    right: -80%;
  }
  .circle5txt::after {
    width: 80px;
    height: 80px;
    right: 0;
  }

  .selection-flow {
    flex-direction: column;
    gap: 80px;
  }

  .selection-step {
    width: 100%;
    max-width: 600px;
    margin-inline: auto;
  }

  .girltxt::after,
  .boytxt::after {
    right: auto;
    left: 50%;
    top: auto;
    bottom: -40px;
    transform: translateX(-50%) rotate(90deg);
  }

  .info-list {
    gap: 16px;
  }
  .info-item {
    padding-block: 30px;
  }

  .info-item img {
    width: 50px;
  }
}

@media screen and (max-width: 836px) {
  .sect-title {
    font-size: 24px;
  }

  .we {
    background-size: min(350px, 100%) auto, 60px auto, 60px auto, 93px auto,
      max(250px, calc(420 / 1440 * 100%)) auto, 50px auto, 50px auto;
    background-position: 50% 400px, 65% calc(58% + 100px), 62% calc(58% - 30px),
      calc(100% - 120px) calc(58% + 180px), 25% calc(58% + 130px),
      calc(100% - 110px) calc(58%), calc(100% - 120px) calc(58% + 270px);
  }
  .service {
    margin-block-start: 20px;
  }
  .technology {
    overflow: hidden;
  }
  .cloud1explain,
  .cloud2explain,
  .cloud3explain,
  .cloud4explain,
  .cloud5explain {
    width: 100%;
    max-width: 480px;
    margin-inline: auto;
  }

  .cloud4txt {
    margin-block-start: 300px;
  }

  .cloud2explain {
    margin-block-start: 220px;
  }

  .cloud3explain {
    margin-block-start: 0;
  }
  .cloud4explain {
    margin-block-start: 0;
    margin-block-end: 0;
  }
  .cloud5explain {
    margin-block-start: 0;
  }

  .technology-grid {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 500px;
    margin-inline: auto;
  }

  .circle1,
  .circle3,
  .circle5 {
    translate: 0;
    align-self: flex-start;
  }
  .circle2 {
    translate: 0;
    align-self: flex-end;
  }
  .circle3 {
    align-self: flex-end;
  }
  .circle4 {
    align-self: center;
    margin-block-start: 250px;
  }

  .circle5 {
    margin-block-start: 80px;
  }

  .circle2txt::before {
    left: auto;
    right: -6%;
    bottom: -10%;
    width: 60px;
    height: 60px;
  }
  .circle2txt::after {
    left: auto;
    right: -30%;
    top: -70%;
  }

  .circle3txt::before {
    bottom: -10%;
    right: auto;
    left: -185%;
    width: 76px;
    height: 76px;
  }
  .circle3txt::after {
    bottom: auto;
    right: auto;
    top: -10%;
    left: -200%;
    width: 150px;
    height: 134px;
  }

  .circle4txt::before {
    width: 76px;
    height: 76px;
    top: -110%;
    right: -50%;
  }

  .circle5txt::before {
    right: auto;
    left: -10%;
    top: -40%;
  }

  .circle5txt::after {
    right: -50%;
    top: -35%;
  }

  .recruit-grid {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    max-width: 420px;
    margin-inline: auto;
    margin-block-start: 60px;
  }

  .recruit-grid::after {
    width: 80%;
    translate: 0 -5%;
    top: 53%;
  }

  .two::before {
    top: 0;
  }
  .two::after {
    bottom: -100px;
    left: auto;
    right: 0;
  }

  .three {
    margin-block-start: 350px;
  }

  .four::before {
    top: -20%;
    right: -20px;
  }

  .onetxt,
  .twotxt,
  .threetxt,
  .fourtxt {
    font-size: 18px;
  }

  .conditions {
    flex-direction: column;
    gap: 50px;
  }

  .treatment,
  .vacation {
    box-sizing: border-box;
    margin-inline: auto;
    width: 100%;
    max-width: 600px;
  }

  .part {
    width: calc(680 / 1080 * 100%);
    padding: 30px 16px;
  }

  .part::before {
    right: -58%;
    width: 145px;
  }

  .partttl {
    font-size: 18px;
  }

  .partexplain {
    font-size: 16px;
  }

  .point {
    font-size: 21px;
    padding: 55px 20px 45px 150px;
  }

  .box::after {
    width: 105px;
    height: 140px;
  }

  .info-list {
    margin-block-start: 80px;
  }

  .info-item {
    width: calc((100% - 32px) / 3);
  }

  .infotxt {
    margin-block-start: 40px;
  }

  .info-explain {
    margin-block-start: 20px;
  }

  .mail2::before {
    width: 53px;
    height: 70px;
    top: -70px;
  }

  .mail2::after {
    width: 50px;
    height: 53px;
    top: -53px;
  }

  .twitter1::before {
    width: 100px;
    height: 68px;
    top: -60px;
  }

  .footerul {
    width: 100%;
    max-width: 350px;
  }

  .footerul ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
  }

  .footerul li {
    padding: 0;
    width: calc(50% - 8px);
    text-align: left;
  }

  .footerlogo {
    width: 110px;
    height: 70px;
    left: 50%;
    top: 45%;
    transform: translate(-50%, 20px);
  }

  .footerimg {
    margin-block-start: 100px;
  }
}

@media screen and (max-width: 550px) {
  .br-sp {
    display: block;
  }

  .firstview {
    background-image: url(../src/img/first view/Frame.png),
      url(../src/img/first view/Frame2.png),
      url(../src/img/first view/Frame6.png),
      url(../src/img/first view/Frame9.png),
      url(../src/img/first view/Frame10.png),
      url(../src/img/first view/Frame7.png);
    background-size: calc(111 / 480 * 100%) auto, calc(96 / 480 * 100%) auto,
      calc(92 / 480 * 100%) auto, calc(160 / 480 * 100%) auto,
      calc(90 / 480 * 100%) auto, calc(89 / 480 * 100%) auto;
    padding-block-end: 120px;
    background-position: 20px 75%, 20px 0, 33% bottom, 88% 80%,
      calc(100% - 15px) 20px, 55% 85%;
    padding-block-end: 220px;
  }

  .hptitle {
    width: 100%;
    max-width: 1000px;
    min-width: 320px;
    aspect-ratio: 991 / 550;
    height: auto;
    mix-blend-mode: darken;
  }

  .hptitle img {
    width: 100%;
  }

  .we {
    background-position: 50% 18%, 65% calc(57% + 100px), 57% calc(57% - 70px),
      calc(100% - 90px) calc(57% + 180px), 25% calc(57% + 130px),
      calc(100% - 80px) calc(57%), calc(100% - 90px) calc(57% + 245px);
  }

  .we-img {
    margin-block-start: 30px;
  }

  .cloud3explain {
    translate: 0 0;
  }

  .technology-grid {
    margin-block-start: 40px;
  }

  .circle3txt::before {
    left: -105%;
  }
  .circle3txt::after {
    left: -120%;
  }
  .circle4txt::before {
    right: -30%;
  }

  .circle5txt::before {
    left: 0;
  }

  .part {
    width: 80%;
    max-width: 500px;
    margin-inline: auto;
    padding: 30px 16px;
  }

  .part::before {
    right: 5%;
    top: auto;
    bottom: -220px;
    width: 120px;
  }

  .part::after {
    width: 30px;
    height: 70px;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    top: auto;
    bottom: -69px;
    clip-path: polygon(0 0, 80% 0, 100% 100%);
  }

  .merit {
    margin-block-start: 10px;
  }

  .point {
    margin-block-start: 150px;
    font-size: 18px;
    padding-inline-start: 120px;
  }

  .info-item img {
    width: 36px;
  }
  .info-item {
    padding: 20px 16px;
  }

  .info-explain {
    margin-block-start: 20px;
    font-size: 14px;
  }
}

@media screen and (max-width: 460px) {
  .header {
    height: auto;
    position: fixed;
    z-index: 100;
  }

  .pc-menu {
    display: none;
    padding-left: 10px;
  }

  .sp-menu {
    display: block;
    width: 100%;
    padding-inline-end: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .footer .pc-menu {
    display: block;
  }

  .footer .sp-menu {
    display: none;
  }

  .drawer-btn {
    z-index: 999;
    translate: 0 5px;
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 999;
  }
  .drawer-btn span {
    display: block;
    width: 20px;
    height: 3px;
    background-color: #333333;
    margin-block-end: 5px;
    border-radius: 999px;
  }

  .drawer-btn.open span:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .drawer-btn.open span:nth-child(2) {
    opacity: 0;
  }

  .drawer-btn.open span:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  .drawer {
    display: block;
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: #f8ea76;
    transition: left 0.3s ease-in-out;
    z-index: 200;
  }

  .drawer.open {
    left: 0;
  }

  .drawer ul {
    padding: 30px 20px;
  }

  .drawer li {
    list-style: none;
    font-weight: 700;
    padding: 16px;
    border-bottom: 1px dashed #000;
  }

  .firstview-wrapper {
    padding-block-start: 70px;
  }

  .firstview {
    padding-block-end: 150px;
  }

  .we {
    background-position: 50% 15%, 75% calc(57% + 100px), 71% calc(57% - 60px),
      calc(100% - 30px) calc(57% + 170px), 10% calc(57% + 130px),
      calc(100% - 40px) calc(57%), calc(100% - 30px) calc(57% + 245px);
  }

  .circle4txt::before {
    right: 0;
  }
  .circle4txt::before {
    right: -15%;
  }

  .mail2::before {
    width: 32px;
    height: 42px;
    top: -42px;
  }

  .mail2::after {
    width: 30px;
    height: 32px;
    top: -32px;
  }

  .twitter1::before {
    width: 60px;
    height: 40px;
    top: -32px;
  }
}
