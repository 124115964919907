* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.geneAIText {
    font-size: 1.8em;
    color: rgb(174 221 243);
}

.fontweight{
    font-weight: bold;

}

header {
    background: #fff;
    color: #333;
    padding: 1em 0;
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

nav .logo {
    padding-left: 10px;
    display: flex;
    align-items: center;
}

nav .logo img {
    height: 40px;
    margin-right: 10px;
}

nav .logo h1 a {
    font-size: 1.2em;
    text-decoration: none;
    color: #333;
}

nav .contact {
    display: flex;
    align-items: center;
    padding-right: 10px;
}

nav .contact .btn {
    display: inline-block;
    padding: 0.75em 1.5em;
    background: #ff6347;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background 0.3s ease;
}

nav .contact .btn:hover {
    background: #e55347;
}

#intro {
    background: #eee;
    padding: 2em 0;
    text-align: center;
}

#intro p {
    font-size: 1.2em;
}

#services {
    padding: 2em 0;
    text-align: center;
}

#services h3 {
    margin-bottom: 1em;
    font-size: 2em;
}

.service-box {
    display: inline-block;
    width: 18%;
    margin: 1%;
    padding: 1em;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
}

.service-box i {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 0.5em;
}

.service-box h4 {
    font-size: 1.2em;
    margin-bottom: 0.5em;
}

#details {
    padding: 2em 2em;
    background: #f9f9f9;
}

#details img {
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 9999px;
    width: 40%;
}

#details h3 {
    font-size: 1.8em;
    margin-bottom: 0.5em;
}

#details p {
    margin-bottom: 1.5em;
    line-height: 1.8;
}

#video {
    background: #eee;
    padding: 2em 0;
    text-align: center;
}

#video .btn {
    display: inline-block;
    border-radius: 9999px;
    padding: 0.75em 1.5em;
    background: #333;
    color: #fff;
    text-decoration: none;
    transition: background 0.3s ease;
}

#video .btn:hover {
    background: #555;
}

footer {
    background: #333;
    color: #fff;
    text-align: center;
    padding: 1em 0;
    margin-top: 2em;
}

@media (max-width: 768px) {
    body {
        padding: 0 1em;
    }

    .service-box {
        width: 45%;
        margin: 2.5%;
    }
}

@media (max-width: 480px) {
    header h1 {
        font-size: 2em;
    }

    header h2 {
        font-size: 1.2em;
    }

    .service-box {
        width: 100%;
        margin: 1em 0;
    }

    .hero-content h1 {
        font-size: 2.5em;
    }

    .hero-content p {
        font-size: 1em;
    }
}

#hero {
    background: url('../img/generative_ai/firstView.png') no-repeat center center/cover;
    height: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    padding: 0 1em;
}

.hero-content {
    z-index: 2;
}

.hero-content h1 {
    font-size: 3em;
    margin-bottom: 0.5em;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* テキストシャドウ */
}

.hero-content p {
    font-size: 1.2em;
    margin-bottom: 1em;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* テキストシャドウ */
}

.hero-content .btn {
    display: inline-block;
    border-radius: 9999px;
    padding: 0.75em 1.5em;
    background: #ff6347;
    color: #fff;
    text-decoration: none;
    transition: background 0.3s ease;
}

.hero-content .btn:hover {
    background: #e55347;
}

.container .btn {
    display: inline-block;
    border-radius: 9999px;
    padding: 0.75em 1.5em;
    background: #ff6347;
    color: #fff;
    text-decoration: none;
    transition: background 0.3s ease;
}

.container .btn:hover {
    background: #e55347;
}


#process, #faq {
    padding: 2em 2em;
    text-align: center;
    background: #f9f9f9;
}

#process h3, #faq h3 {
    font-size: 2em;
    margin-bottom: 1em;
}

.process-step, .faq-item {
    margin-bottom: 1.5em;
    text-align: left;
}

.process-step h4, .faq-item h4 {
    font-size: 1.5em;
    margin-bottom: 0.5em;
}

.process-step p, .faq-item p {
    font-size: 1em;
    line-height: 1.6;
}
